import axios from "axios";
import { requestAccessToken } from "./AuthServices";

export const getAgreementsGridData = async (emailId) => {
  try {
    let access = await requestAccessToken();
    console.log(access, "accessToken");
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/Get?EmailAddress=${emailId}`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getAgreementHtml = async (
  agreementId,
  width = null,
  height = null
) => {
  try {
    width = 500;
    height = 250;
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/GetAgreementHTML?AgreementId=${agreementId}&width=${width}&height=${height}`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getStates = async () => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Services/GetStates`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getEnduserClassification = async () => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Services/GetEUClassifications`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getAgreementType = async (company = null) => {
  try {
    company = 1;
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/GetTypes?Company=${company}`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getTechnologyTypes = async (company = null) => {
  try {
    company = 1;
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Services/GetTechnologyTypes?Company=${company}`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getMainMenuItems = async (emailId = null) => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const endpoint = emailId === null ? "/Services/GetDefaultMainMenuItems" : `/Services/GetMainMenuItems?EmailAddress=${emailId}`;
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${endpoint}`, options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const addNewAgreement = async (body) => {
  let access = await requestAccessToken();
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access.token}`,
      },
      body: JSON.stringify(body),
    };

    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/New`,
      requestOptions
    );
    let responseObject = {
      statusCode: response.status,
      message: await response.json(),
      ok: response.ok,
    };
    return responseObject;
  } catch (e) {
    console.log(e);
  }
};

export const updateAgreement = async (body) => {
  let access = await requestAccessToken();
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access.token}`,
      },
      body: JSON.stringify(body),
    };
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/Update`,
      requestOptions
    );
    let responseObject = {
      statusCode: response.status,
      message: await response.json(),
      ok: response.ok,
    };
    return responseObject;
  } catch (e) {
    console.log(e);
  }
};

export const getAgreementBYId = async (agreementId = null) => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/GetById?id=${agreementId}`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getProvinces = async () => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Services/GetProvinces`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getCountries = async () => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Services/GetContries`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getUserType = async (emailId = null) => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Users/GetUserType?SSOID=${emailId}`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const setHasSeenProgramDescription = async (agreementId) => {
  let access = await requestAccessToken();
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access.token}`,
      },
    };
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/SetHasSeenProgramDescription?AgreementId=${agreementId}`,
      requestOptions
    );
    let responseObject = {
      statusCode: response.status,
      message: await response.json(),
      ok: response.ok,
    };
    return responseObject;
  } catch (e) {
    console.log(e);
  }
};

export const getAgreementDetailsById = async (agreementId) => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/GetById?id=${agreementId}`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const setAgreementSigned = async (agreementId) => {
  let access = await requestAccessToken();
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access.token}`,
      },
    };
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/setAgreementSigned?AgreementId=${agreementId}`,
      requestOptions
    );
    let responseObject = {
      statusCode: response.status,
      message: await response.json(),
      ok: response.ok,
    };
    return responseObject;
  } catch (e) {
    console.log(e);
  }
};

export const deletePointOfContact = async (body) => {
  try {
    let access = await requestAccessToken();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access.token}`,
      },
      body: JSON.stringify(body),
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/DeletePointOfContact`,
      requestOptions
    );
    console.log(response, "Response");
    let responseObject = {
      statusCode: response.status,
      message: await response.json(),
      ok: response.ok,
    };
    return responseObject;
  } catch (e) {
    console.log(e);
  }
};

export const updatePointOfContact = async (body) => {
  try {
    let access = await requestAccessToken();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access.token}`,
      },
      body: JSON.stringify(body),
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/UpdatePointOfContact`,
      requestOptions
    );
    console.log(response, "Response");
    let responseObject = {
      statusCode: response.status,
      message: await response.json(),
      ok: response.ok,
    };
    return responseObject;
  } catch (e) {
    console.log(e);
  }
};

export const addPointOfContact = async (body) => {
  try {
    let access = await requestAccessToken();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access.token}`,
      },
      body: JSON.stringify(body),
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/AddPointOfContact`,
      requestOptions
    );
    console.log(response, "Response");
    let responseObject = {
      statusCode: response.status,
      message: await response.json(),
      ok: response.ok,
    };
    return responseObject;
  } catch (e) {
    console.log(e);
  }
};

export const getKeyingSchedule = async (agreementId) => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/GetKeyingSchedule?AgreementId=${agreementId}`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const updateKeyingScheduleLine = async (body) => {
  try {
    let access = await requestAccessToken();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access.token}`,
      },
      body: JSON.stringify(body),
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/UpdateKeyingScheduleLine`,
      requestOptions
    );
    console.log(response, "Response");
    let responseObject = {
      statusCode: response.status,
      message: await response.json(),
      ok: response.ok,
    };
    return responseObject;
  } catch (e) {
    console.log(e);
  }
};

export const addKeyingScheduleLine = async (body) => {
  try {
    let access = await requestAccessToken();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access.token}`,
      },
      body: JSON.stringify(body),
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/AddKeyingScheduleLine`,
      requestOptions
    );
    console.log(response, "Response");
    let responseObject = {
      statusCode: response.status,
      message: await response.json(),
      ok: response.ok,
    };
    return responseObject;
  } catch (e) {
    console.log(e);
  }
};

export const deleteKeyingScheduleLine = async (agreementId, lineNumber) => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/DeleteKeyingScheduleLine?AgreementId=${agreementId}&LineNumber=${lineNumber}`,
      options
    );
    console.log(response, "Response");
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

// Agreements/KeyingScheduleBulkUpload
export const keyingScheduleBulkUpload = async (body, overWriteFlag) => {
  try {
    let access = await requestAccessToken();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access.token}`,
      },
      body: body,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/KeyingScheduleBulkUpload?OverwriteExisting=${overWriteFlag}`,
      requestOptions
    );
    console.log(response, "Response");
    let responseObject = {
      statusCode: response.status,
      message: await response.json(),
      ok: response.ok,
    };
    console.log(responseObject, "responseObject");
    return responseObject;
  } catch (e) {
    console.log(e);
  }
};

export const setPrimaryUser = async (pocId) => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/SetPrimaryUser?PointOfContactId=${pocId}`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getResources = async (agreementId) => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Services/GetResources?AgreementId=${agreementId}`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getAgreementStatus = async (agreementId) => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/GetStatus?AgreementId=${agreementId}`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getCoiningDieTemplate = async (bowSize, agreementId) => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/GetCoiningDieTemplate?AgreementId=${agreementId}&BowSize=${bowSize}`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const uploadCoingdieArtwork = async (body, agreementId) => {
  try {
    let access = await requestAccessToken();
    const formData = new FormData();
    formData.append("CoiningDieImg", body, body.name);
    const url = `${process.env.REACT_APP_API_URL}/Agreements/SaveCoiningDieCustomArtwork/${agreementId}`;
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${access.token}`,
      },
    });
    console.log(response, "Response");
    let responseObject = {
      statusCode: response.status,
      message: await response.text,
      ok: response.statusText === "OK",
    };
    console.log(responseObject, "responseObject");
    return responseObject;
  } catch (e) {
    console.log(e);
  }
};

export const uploadCoingdie = async (body, agreementId) => {
  try {
    let access = await requestAccessToken();
    const formData = new FormData();
    formData.append("CoiningDieImg", body, body.name);
    const url = `${process.env.REACT_APP_API_URL}/Agreements/SaveCoiningDieFullProof/${agreementId}`;
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${access.token}`,
      },
    });
    console.log(response, "Response");
    let responseObject = {
      statusCode: response.status,
      message: await response.text,
      ok: response.statusText === "OK",
    };
    console.log(responseObject, "responseObject");
    return responseObject;
  } catch (e) {
    console.log(e);
  }
};

export const getBlinkIdentification = async (agreementId) => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "POST",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/GetBlindIdentification/${agreementId}`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getUserTypes = async () => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;

    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Fusion/Users/GetUserTypes`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const createNewUser = async (body) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/Fusion/Users/Add`,
      requestOptions
    );
    let responseObject = {
      statusCode: response.status,
      message: await response.json(),
      ok: response.ok,
    };
    return responseObject;
  } catch (e) {
    console.log(e);
  }
};

export const getMkSystem = async (agreementId) => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/GetMKSystem/${agreementId}`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getCoiningDieProof = async (agreementId) => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/GetCoiningDieProof/${agreementId}`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const submitAgreement = async (agreementId, body) => {
  try {
    console.log(body, "selectedProfessionalServiceBody");
    let access = await requestAccessToken();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access.token}`,
      },
      body: JSON.stringify(body),
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/SubmitAgreement/${agreementId}`,
      requestOptions
    );
    let responseObject = {
      statusCode: response.status,
      message: await response.json(),
      ok: response.ok,
    };
    return responseObject;
  } catch (e) {
    console.log(e);
  }
};

export const getProfessionalServicesOptions = async (agreementId) => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/GetProfessionalServicesOptions/${agreementId}`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getFinishOption = async () => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Services/GetFinishes`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const generateOTP = async (agreementId) => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/GenerateOPT/${agreementId}`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const validateOTP = async (agreementId, otp) => {
  try {
    let access = await requestAccessToken();
    const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/ValidateOTP/${agreementId}?OTP=${otp}`,
      options
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getAgreementPdf = async (agreementId) => {
  try {
    let access = await requestAccessToken();
    // const headers = new Headers();
    const bearer = `Bearer ${access.token}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        "Authorization": bearer,
      }
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Agreements/Adobe/GetAgreementPDF/${agreementId}`,
      options
    );
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    console.log(url, 'getCompletedAgreementPdf url');
    return url; 
  } catch (e) {
    console.log(e);
  }
};

//
//API call to verify the google captcha generated via script
export const verifyRecaptcha = async (recaptchaToken) => {

  try {
    // Send a POST request to the Google reCAPTCHA verification endpoint
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/fusion/CrossReference/VerifyRecaptcha`,
      {
        token: recaptchaToken
      },
      {
        headers: {
          "Content-Type": "application/json", // Set appropriate headers
        },
      }
    );
    console.log("Response:", response);

    // Check if the reCAPTCHA verification was successful and the score is above 0.5
    if (response.data.success && response.data.score >= 0.5) {
      console.log("reCAPTCHA passed:", response.data);
      return true; // Verification successful
    } else {
      console.log("reCAPTCHA failed:", response.data);
      return false; // Verification failed
    }
  } catch (error) {
    console.error("Error verifying reCAPTCHA:", error);
    return false;
  }
};